<script setup lang="ts">
import type { PropType } from '@vue/runtime-core';

export interface ProfileItem {
  title?: string;
  name?: string;
  qualifications?: string;
  // large image
  photo?: string;
  // small image
  avatar?: string;
  intro?: string;
}

defineProps({
  items: {
    type: Array as PropType<ProfileItem[]>,
  },
  itemWidth: {
    type: String,
    default: '280px',
  },
});

defineEmits({
  itemClick: (item: ProfileItem, index: number) => true,
  more: (item: ProfileItem, index: number) => true,
});

const uid = Math.random().toString(36).substring(2);
</script>

<template>
  <div class="grid">
    <div
      class="item"
      v-for="(item, i) in items"
      :key="i"
      @click="$emit('itemClick', item, i)"
    >
      <img class="image mb-4" :src="item.avatar || item.photo" alt="" />
      <div class="name my-2">{{ item.name }}</div>
      <HorizontalRule class="mt-3">
        <template #post>
          <button class="icon" @click="$emit('more', item, i)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 47">
              <defs>
                <mask :id="`mask-${uid}`">
                  <circle cx="23.5" cy="23.5" r="23.5" fill="white" />
                  <rect x="15" y="22" width="17" height="3" fill="black" />
                  <rect
                    x="25"
                    y="15"
                    width="17"
                    height="3"
                    transform="rotate(90 25 15)"
                    fill="black"
                  />
                </mask>
              </defs>
              <rect
                :mask="`url(#${`mask-${uid}`})`"
                width="47"
                height="47"
                fill="currentColor"
              />
            </svg>
          </button>
        </template>
      </HorizontalRule>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.grid {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-down(md) {
    justify-content: space-around;
  }

  .item {
    width: v-bind(itemWidth);
    margin: 50px 30px;

    @include media-breakpoint-down(sm) {
      margin: 16px 8px;
    }
  }

  .image {
    object-fit: cover;
    width: 100%;
    border-radius: 15px;
    aspect-ratio: 3 / 4;
    background-color: var(--bs-gray-300);
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .name {
    font-size: 22px;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  .qualification {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.25em;
    height: 2.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  button.icon {
    border: none;
    background: transparent;
    color: inherit;
    padding: 0;
    transition: all 0.2s ease-out;
    line-height: 0;
    width: 47px;
    height: 47px;

    @include media-breakpoint-down(sm) {
      width: 31px;
      height: 31px;
    }

    &:hover,
    &:focus {
      rotate: 90deg;
    }
  }
}
</style>
