<script setup lang="ts">
const uid = Math.random().toString(36).substring(2);

const props = defineProps({
  show: Boolean,
  showClose: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits({
  close: () => true,
});
const close = () => emit('close');

if (process.client) {
  watch(
    () => props.show,
    (val) => {
      if (val) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
    { immediate: true },
  );
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask" @click.self="close">
      <div class="modal-container">
        <slot />
        <button
          v-if="showClose"
          class="closeBtn"
          @click="close"
          aria-label="close"
        >
          <svg viewBox="0 0 67 67" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <mask :id="`mask-${uid}`">
                <circle cx="33.5" cy="33.5" r="33.5" fill="white" />
                <rect
                  x="28.5503"
                  y="26.4297"
                  width="17"
                  height="3"
                  transform="rotate(45 28.5503 26.4297)"
                  fill="black"
                />
                <rect
                  x="40.571"
                  y="28.5508"
                  width="17"
                  height="3"
                  transform="rotate(135 40.571 28.5508)"
                  fill="black"
                />
              </mask>
            </defs>
            <rect
              :mask="`url(#${`mask-${uid}`})`"
              fill="currentColor"
              width="67"
              height="67"
            />
          </svg>
        </button>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.closeBtn {
  border: none;
  background: transparent;
  padding: 0;
  line-height: 0;
  position: absolute;
  top: 36px;
  right: 36px;

  svg {
    width: 67px;
    height: 67px;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  display: flex;
  transition: opacity 0.3s ease;
  padding: 16px;

  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.modal-container {
  margin: auto;
  position: relative;
  background-color: var(--bs-body-bg);
  border-radius: 15px;
  transition: all 0.3s ease;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  transform: scale(1.1);
}
</style>
