<script setup>
defineProps({
  background: {
    type: String,
  },
  backgroundScrim: {
    type: String,
  },
  title: {
    type: String,
  },
  fluid: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();
</script>

<template>
  <section :class="{ scrim: !!backgroundScrim }">
    <div v-if="title || slots.default" :class="{ container: !fluid }">
      <slot>
        <h1>{{ title }}</h1>
      </slot>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

section {
  background: v-bind(background);
  background-size: cover;
  min-height: 680px;
  display: flex;
  align-items: center;
  position: relative;

  &.scrim:before {
    content: '';
    display: block;
    background: v-bind(backgroundScrim);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.container {
  position: relative;
}

h1 {
  color: $body-bg;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  white-space: pre-wrap;
}
</style>
